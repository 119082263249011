<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ icons.mdiFormatListChecks }}
      </v-icon>
      <span class="text-break">Rule Settings</span>
    </v-card-title>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            v-show="mode === 'update'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="ruleData.id"
              label="Rule ID"
              dense
              outlined
              :disabled="true"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="ruleData.label"
              label="Label"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="ruleData.control"
              :rules="requiredRule"
              :disabled="mode === 'update'"
              dense
              outlined
              clearable
              label="Control *"
              hide-details="auto"
              :items="controls"
              item-text="label"
              item-value="id"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <div v-if="ruleData.status === 'LOCKED'">
              Status: <strong>{{ controlData.status }}</strong>
            </div>
            <v-select
              v-if="ruleData.status !== 'LOCKED'"
              v-model="ruleData.status"
              :rules="requiredRule"
              dense
              outlined
              label="Status *"
              :items="status"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-checkbox
              v-model="ruleData.required"
              dense
              outlined
              label="Required"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOffOutline, mdiEyeOutline, mdiFormSelect, mdiFormatListChecks,
} from '@mdi/js'

export default {
  /**
   * props
   */
  props: {
    ruleData: {
      type: Object,
      default: () => {},
    },
    ruleDataOriginal: {
      type: Object,
      default: () => {},
    },
    controls: {
      type: Array,
      default: () => [],
    },
    mode: { type: String, default: undefined },
  },

  /**
   * data
   */
  data() {
    return {
      requiredRule: [
        value => !!value || 'Required',
      ],
    }
  },

  /**
   * methods
   */
  methods: {
    /**
     * df
     */
    df(isoString) {
      return new Date(isoString).toLocaleString()
    },

    /**
     * cancel
     */
    cancel() {
      this.$router.back()
    },
  },

  /**
   * setup
   */
  setup() {
    const status = [
      { text: 'Enabled', value: 'ENABLED' },
      { text: 'Disabled', value: 'DISABLED' },
    ]

    return {
      status,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiFormSelect,
        mdiFormatListChecks,
      },
    }
  },
}
</script>
